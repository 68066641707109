import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
  useCorporationLocations,
  useOrganizationTemplates,
} from '../../../api/hooks/enterprise';
import { SYNC_FROM_ORGANIZATION_TEMPLATE } from '../../../api/mutations/enterprise';
import {
  BaseCard,
  CardHeaderContainer,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  DarkEssText,
  DarkMLText,
  LargeNoDataIcon,
  LargeTinyText,
  MediumDarkExtraSmallText,
  MediumDarkLargeTinyText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallCheckbox,
  StartAlignedFlexDiv,
  StartAlignedLightDarkLargeTinyText,
} from '../../../styles/shared-styled-components';
import {
  organizationTemplateTypeLabelMap,
  textSeparatorChar,
} from '../../../utils/constants';
import LoadingIndicator from '../../LoadingIndicator';

const OrganizationTemplateSyncModal = ({
  currentLocationId,
  isOpen,
  onClose,
  onSync,
}) => {
  // Templates
  const { templates, loading } = useOrganizationTemplates({});
  const { locations, loading: locationsLoading } = useCorporationLocations({});

  const [syncMutation, { loading: syncing }] = useMutation(
    SYNC_FROM_ORGANIZATION_TEMPLATE,
  );

  const locationMap = {};
  locations?.map((l) => {
    let name = l.name;
    if (name?.length > 15) {
      name = `${name.slice(0, 12)}...`;
    }

    locationMap[l.id] = name;
    return { key: l.id, label: name };
  });
  console.log('organizationTemplates', templates);

  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleSync = () => {
    const selectedId = selectedTemplate?.id;
    const selectedLocationName = locationMap[selectedTemplate?.locationId];

    syncMutation({
      variables: {
        templateId: selectedId,
      },
      onCompleted: async () => {
        onSync(selectedLocationName);
        handleClose();
      },
    });
  };

  const handleClose = () => {
    setSelectedTemplate();
    onClose();
  };

  if (loading || locationsLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const hasTemplates = !!templates?.length;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer>
        <DarkMLText>Sync from Organization Templates</DarkMLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper columnFlex>
            <MediumGapColumnCenteredDiv>
              <ColumnCenteredDiv>
                {hasTemplates ? (
                  <>
                    {templates?.map((template) => {
                      const { id, type, locationId } = template;
                      const value = organizationTemplateTypeLabelMap[type];
                      const locationName = locationMap[locationId];
                      const selected = id === selectedTemplate?.id;
                      const sourceIsItself = locationId === currentLocationId;
                      return (
                        <BaseCard
                          selected={selected}
                          disableBoxShadow
                          maxWidth='80%'
                        >
                          <ColumnDivWithGap>
                            <CardHeaderContainer>
                              <DarkEssText>
                                {value?.title} {textSeparatorChar} Saved from '
                                {locationName}' location
                              </DarkEssText>
                            </CardHeaderContainer>
                            <StartAlignedLightDarkLargeTinyText>
                              {value?.sublabel}
                            </StartAlignedLightDarkLargeTinyText>
                            <StartAlignedFlexDiv removeGap>
                              {sourceIsItself ? (
                                <MediumDarkLargeTinyText>
                                  Cannot sync from itself
                                </MediumDarkLargeTinyText>
                              ) : (
                                <>
                                  <SmallCheckbox
                                    onClick={() => {
                                      setSelectedTemplate(
                                        selected ? null : { id, locationId },
                                      );
                                    }}
                                  />
                                  <LargeTinyText>
                                    {selected ? 'Selected' : 'Select'} for
                                    syncing
                                  </LargeTinyText>
                                </>
                              )}
                            </StartAlignedFlexDiv>
                          </ColumnDivWithGap>
                        </BaseCard>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <LargeNoDataIcon />
                    <MediumDarkExtraSmallText>
                      No templates found, create them from the 'Templates' tab
                    </MediumDarkExtraSmallText>
                  </>
                )}
              </ColumnCenteredDiv>
            </MediumGapColumnCenteredDiv>
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions>
          {hasTemplates ? (
            <>
              <MediumPrimaryButton
                onClick={() => {
                  if (selectedTemplate) {
                    handleSync();
                  }
                }}
                disabled={!selectedTemplate}
              >
                Sync template
              </MediumPrimaryButton>
              <MediumSecondaryButton onClick={handleClose}>
                Cancel
              </MediumSecondaryButton>
            </>
          ) : (
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          )}
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default OrganizationTemplateSyncModal;

import styled from 'styled-components';
import {
  EssText,
  MediumDarkEssText,
  SmallText,
} from '../../../styles/shared-styled-components';

export const TableHeaderText = styled(EssText).attrs({
  smallLineHeight: true,
})`
  font-weight: 600;
`;

export const LargeTableHeaderText = styled(SmallText).attrs({
  smallLineHeight: true,
})`
  font-weight: 600;
`;

export const TableRowText = styled(MediumDarkEssText).attrs({
  smallLineHeight: true,
})`
  font-weight: 600;
`;

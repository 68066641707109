import { useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import {
  useClientFilterTemplateById,
  useMyClientFilterTemplates,
} from '../../../api/hooks/client';
import { useMySessionTypes } from '../../../api/hooks/enterprise';
import { UPDATE_SMART_LIST } from '../../../api/mutations/campaign';
import {
  CREATE_CLIENT_FILTER_TEMPLATE,
  DELETE_CLIENT_FILTER_TEMPLATE,
  EDIT_CLIENT_FILTER_TEMPLATE,
} from '../../../api/mutations/client';
import {
  AutoScrollWrapper,
  BookmarkIcon,
  BrightGreenText,
  CenteredDiv,
  CheckedRadioButton,
  ClearInputIcon,
  ClickableMediumDarkEssText,
  ColumnCenteredDiv,
  EmptyGapColumnCenteredDiv,
  FullSizeCenteredDiv,
  LargeNoDataIcon,
  LightDarkLargeTinyText,
  LightDarkSmallText,
  LightPrimaryEssText,
  ListCheckIcon,
  ListEditIcon,
  ListRemoveIcon,
  MLText,
  MediumDarkEssText,
  MediumDarkLargeTinyText,
  MediumDarkSmallText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  MediumText,
  Modal,
  ModalActions,
  ModalContainer,
  ModularWidthInput,
  SMText,
  SmallCheckbox,
  StartAlignedErrorLargeTinyText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
  StartAlignedMediumDarkLargeTinyText,
  StyledBackIcon,
  StyledNextIcon,
  Tab,
  Tabs,
  Tooltip,
  TooltipTitleText,
  UncheckedRadioButton,
} from '../../../styles/shared-styled-components';
import {
  campaignMemberManagementContextKey,
  campaignMemberStatusLabelMap,
  campaignsKey,
  canBeTextedKey,
  clientFilterFieldKeys,
  clientFilterFieldLabelMap,
  contactKey,
  contactTypeKey,
  contactTypeLabelMap,
  containsArrayItems,
  distanceKey,
  dndKey,
  doesNotEqualKey,
  doesntContainArrayItems,
  dropInKey,
  emailKey,
  emptyArrayValue,
  emptyIntValue,
  equalsKey,
  firstNameKey,
  firstVisitKey,
  genderKey,
  greaterThanKey,
  greaterThanOrEqualToKey,
  intraStringSeparatorChar,
  lastMessagedAtKey,
  lastNameKey,
  lastVisitKey,
  lastVisitServicesKey,
  lessThanKey,
  lessThanOrEqualToKey,
  membershipStatusKey,
  nameKey,
  numUpcomingVisitsKey,
  numVisitsKey,
  packageKey,
  packagesLabel,
  phoneKey,
  serviceCategoriesUsedKey,
  sessionTypeTypesMap,
  stagingFilterConversionDelay,
  stringFieldType,
  tagsKey,
  textSeparatorChar,
  uncategorizedKey,
  unclassifiedKey,
  upcomingVisitsKey,
} from '../../../utils/constants';
import { getDaysSinceIntTimestamp } from '../../../utils/date';
import {
  getClientFilterMapFromString,
  getTypesenseFilterString,
} from '../../../utils/filters';
import LoadingIndicator from '../../LoadingIndicator';
import { DownArrow, UpArrow } from '../../Micro/ArrowIcon/styled';
import Snackbar from '../../Snackbar';
import {
  ListInputSection,
  OptionActionsContainer,
  OptionItemContainer,
  OptionSubitemText,
  OptionSubitemTextContainer,
  OptionsSearchBoxContainer,
} from '../../Training/shared-training-components';
import {
  BackToFieldsContainer,
  BackToTemplatesContainer,
  CenteredCheckboxFieldContainer,
  CheckboxFieldContainer,
  EditTemplateContainer,
  EditTemplateTitleContainer,
  FieldContainer,
  FieldsContainer,
  FilterFieldContainer,
  FilterFieldsContainer,
  FilterFieldsSection,
  FilterFieldsTitleContainer,
  FilterOperatorContainer,
  FilterOperatorText,
  FilterOperatorsContainer,
  SaveFilterIconContainer,
  SelectOptionChip,
  SelectOptionContainer,
  SelectOptionText,
  TemplateActionsContainer,
  TemplateContainer,
  TemplatesContainer,
  ToggleAllOptionsText,
  ToggleAllOptionsTextSpan,
} from './styled';

const fieldsTab = 0;
const templatesTab = 1;

const getExcludedFilterFields = (context) => {
  let excludedFilterFields = [nameKey, contactKey];
  if (context === campaignMemberManagementContextKey) {
    excludedFilterFields = [
      ...excludedFilterFields,
      campaignsKey,
      canBeTextedKey,
      dndKey,
    ];
  }
  return excludedFilterFields;
};

const maxAllowedForServicesQuery = 120;

const FilterClientsModal = ({
  isOpen,
  onClose,
  filters,
  locationId,
  onApply,
  tabValue = fieldsTab,
  refetchCampaigns,
  campaignNameMap,
  tags,
  campaignToEdit,
  isEditing,
  context,
}) => {
  const [tab, setTab] = useState(tabValue);
  const [templateToEditId, setTemplateToEditId] = useState();
  const {
    clientFilterTemplates,
    loading: clientFilterTemplatesLoading,
    refetch: refetchClientFilterTemplates,
  } = useMyClientFilterTemplates({
    skipCondition: tab !== templatesTab,
  });
  const { template, loading: templateLoading } = useClientFilterTemplateById({
    id: templateToEditId,
  });

  const {
    sessionTypes: sessionTypeCatalog,
    loading: sessionTypesLoading,
    refetch: refetchSessionTypeCatalog,
  } = useMySessionTypes({
    onlyBookable: false,
  });

  const [templateToEditName, setTemplateToEditName] = useState(template?.name);

  const [
    createClientFilterTemplateMutation,
    { loading: createClientFilterTemplateLoading },
  ] = useMutation(CREATE_CLIENT_FILTER_TEMPLATE);
  const [
    editClientFilterTemplateMutation,
    { loading: editClientFilterTemplateLoading },
  ] = useMutation(EDIT_CLIENT_FILTER_TEMPLATE);
  const [
    deleteClientFilterTemplateMutation,
    { loading: deleteClientFilterTemplateLoading },
  ] = useMutation(DELETE_CLIENT_FILTER_TEMPLATE);
  const [updateSmartListMutation, { loading: updateSmartListMutationLoading }] =
    useMutation(UPDATE_SMART_LIST);

  const [fieldToFilterOn, setFieldToFilterOn] = useState();
  const [fieldToFilterOnIsNull, setFieldToFilterOnIsNull] = useState();
  const [filterFieldOperator1, setFilterFieldOperator1] = useState(equalsKey);
  const [filterFieldValue1, setFilterFieldValue1] = useState('');
  const [filterFieldOperator2, setFilterFieldOperator2] = useState(equalsKey);
  const [filterFieldValue2, setFilterFieldValue2] = useState('');
  const [filterFieldValue3, setFilterFieldValue3] = useState('');
  const [filterFieldValue4, setFilterFieldValue4] = useState('');
  const [valuesChangedFlag, setValuesChangedFlag] = useState(false);
  const [updatedFilterString, setUpdatedFilterString] = useState('');
  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [collapsedCategoryIds, setCollapsedCategoryIds] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const categoriesMap = sessionTypeCatalog?.categories || {};
  const allCategories = Object.values(categoriesMap);
  const uncategorizedMap = sessionTypeCatalog?.[uncategorizedKey];
  const categoryLabelMap = {};
  const sortedAvailableSessionTypes = sortBy(availableSessionTypes, (s) => {
    const categoryName = s.categoryName;
    categoryLabelMap[categoryName] = categoryName;
    return categoryName === packagesLabel
      ? 'zzzz'
      : categoryName === unclassifiedKey || !categoryName
      ? 'zzz'
      : categoryName;
  });

  const lastServiceUsedFilter = filters?.[lastVisitServicesKey]?.[0];
  const stringifiedLastServiceUsedFilter = JSON.stringify(
    lastServiceUsedFilter || {},
  );
  const lastVisitServicesUsedOperator = lastServiceUsedFilter?.operator;
  const lastVisitServicesUsedValue = lastServiceUsedFilter?.value?.split(', ');

  useEffect(() => {
    if (sessionTypeCatalog) {
      const updatedSelectedServices = {};
      const lastVisitServicesUsedMap = {};
      const contains =
        lastVisitServicesUsedOperator === containsArrayItems ||
        !lastVisitServicesUsedOperator;
      if (lastVisitServicesUsedValue?.length) {
        lastVisitServicesUsedValue.map((id) => {
          lastVisitServicesUsedMap[id] = true;

          if (
            id === emptyArrayValue &&
            (lastVisitServicesUsedOperator === containsArrayItems ||
              !lastVisitServicesUsedOperator)
          ) {
            updatedSelectedServices[emptyArrayValue] = true;
          }
        });

        if (!contains && !lastVisitServicesUsedMap[emptyArrayValue]) {
          updatedSelectedServices[emptyArrayValue] = true;
        }
      } else {
        updatedSelectedServices[emptyArrayValue] = true;
      }

      const updateSelectedServices = (id) => {
        if (!lastVisitServicesUsedOperator) {
          updatedSelectedServices[id] = true;
        } else if (contains && lastVisitServicesUsedMap[id]) {
          updatedSelectedServices[id] = true;
        } else if (
          lastVisitServicesUsedOperator === doesntContainArrayItems &&
          !lastVisitServicesUsedMap[id]
        ) {
          updatedSelectedServices[id] = true;
        }
      };

      let allSessionTypes = [];
      allCategories.map((c) => {
        const allCategoryServices = [];
        (c?.dropIns || []).map((d) => {
          updateSelectedServices(d.id);
          allCategoryServices.push(d);
        });
        (c?.consultations || []).map((c) => {
          updateSelectedServices(c.id);
          allCategoryServices.push(c);
        });
        (c?.addOns || []).map((a) => {
          updateSelectedServices(a.id);
          allCategoryServices.push(a);
        });
        allSessionTypes.push({
          categoryId: c.id,
          categoryName: c.name,
          services: allCategoryServices,
        });
      });

      const allUncategorizedServices = [];
      (uncategorizedMap?.dropIns || []).map((d) => {
        updateSelectedServices(d.id);
        allUncategorizedServices.push(d);
      });
      (uncategorizedMap?.consultations || []).map((c) => {
        updateSelectedServices(c.id);
        allUncategorizedServices.push(c);
      });
      (uncategorizedMap?.addOns || []).map((a) => {
        updateSelectedServices(a.id);
        allUncategorizedServices.push(a);
      });
      allSessionTypes.push({
        categoryId: uncategorizedKey,
        categoryName: 'Uncategorized',
        services: allUncategorizedServices,
      });

      setSelectedServices(updatedSelectedServices);
      setAvailableSessionTypes(allSessionTypes);
    }
  }, [sessionTypeCatalog, stringifiedLastServiceUsedFilter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    if (template) {
      setTemplateToEditName(template.name);
    }
  }, [template]);

  const handleClose = () => {
    setFieldToFilterOn();
    clearFilterValues();
    onClose();
  };

  const handleUpdate = (campaignId, newFilter) => {
    updateSmartListMutation({
      variables: {
        id: campaignId,
        filter: newFilter,
      },
      onCompleted: async (data) => {
        if (data) {
          setSnackbarMessage('Smart list updated');
          setValuesChangedFlag(false);
          onClose();
          await refetchCampaigns();
        }
      },
    });
  };

  const setDefaultFilterValues = (fieldType, key) => {
    if (key === nameKey) {
      const firstNameFilter = filters?.[firstNameKey]?.[0];
      const lastNameFilter = filters?.[lastNameKey]?.[0];
      setFilterFieldValue1(firstNameFilter?.value);
      setFilterFieldOperator1(firstNameFilter?.operator || equalsKey);
      setFilterFieldValue2(lastNameFilter?.value);
      setFilterFieldOperator2(lastNameFilter?.operator || equalsKey);
    } else if (key === upcomingVisitsKey) {
      const appliedDaysUntilFilter = filters?.[upcomingVisitsKey] || [];
      const daysUntilGreaterThanFilter = appliedDaysUntilFilter.find(
        (f) => f.operator === greaterThanOrEqualToKey,
      );
      if (daysUntilGreaterThanFilter) {
        setFilterFieldValue1(parseInt(daysUntilGreaterThanFilter?.value));
      }
      const daysUntilLessThanFilter = appliedDaysUntilFilter.find(
        (f) => f.operator === lessThanOrEqualToKey,
      );
      if (daysUntilLessThanFilter) {
        setFilterFieldValue2(parseInt(daysUntilLessThanFilter?.value));
      }

      const nullFilter = appliedDaysUntilFilter.find(
        (f) => parseInt(f.value) === -1,
      );
      if (nullFilter?.operator === equalsKey) {
        setFieldToFilterOnIsNull(true);
      } else if (nullFilter?.operator === doesNotEqualKey) {
        setFieldToFilterOnIsNull(false);
      } else {
        setFieldToFilterOnIsNull();
      }

      const appliedNumUpcomingVisitsFilter =
        filters?.[numUpcomingVisitsKey] || [];
      const numGreaterThanFilter = appliedNumUpcomingVisitsFilter.find(
        (f) => f.operator === greaterThanOrEqualToKey,
      );
      if (numGreaterThanFilter) {
        setFilterFieldValue3(parseInt(numGreaterThanFilter?.value));
      }
      const numLessThanFilter = appliedNumUpcomingVisitsFilter.find(
        (f) => f.operator === lessThanOrEqualToKey,
      );
      if (numLessThanFilter) {
        setFilterFieldValue4(parseInt(numLessThanFilter?.value));
      }
    } else if (fieldType === stringFieldType) {
      const appliedFilter = filters?.[key]?.[0];
      setFilterFieldValue1(appliedFilter?.value);
      setFilterFieldOperator1(appliedFilter?.operator || equalsKey);
    } else if (
      key === distanceKey ||
      key === lastVisitKey ||
      key === firstVisitKey ||
      key === numVisitsKey
    ) {
      const appliedFilters = filters?.[key] || [];
      const greaterThanOrEqualToFilter = appliedFilters.find(
        (f) => f.operator === greaterThanOrEqualToKey,
      );

      if (greaterThanOrEqualToFilter) {
        setFilterFieldValue1(
          parseInt(
            key === distanceKey
              ? greaterThanOrEqualToFilter?.value / 1.6 / 1000
              : greaterThanOrEqualToFilter?.value,
          ),
        );
      }
      const lessThanOrEqualToFilter = appliedFilters.find(
        (f) => f.operator === lessThanOrEqualToKey,
      );
      if (lessThanOrEqualToFilter) {
        setFilterFieldValue2(
          parseInt(
            key === distanceKey
              ? lessThanOrEqualToFilter?.value / 1.6 / 1000
              : lessThanOrEqualToFilter?.value,
          ),
        );
      }

      const nullFilter = appliedFilters.find((f) => parseInt(f.value) === -1);
      if (nullFilter?.operator === equalsKey) {
        setFieldToFilterOnIsNull(true);
      } else if (nullFilter?.operator === doesNotEqualKey) {
        setFieldToFilterOnIsNull(false);
      } else {
        setFieldToFilterOnIsNull();
      }
    } else if (key === lastMessagedAtKey) {
      const appliedFilters = filters?.[key] || [];

      const lessThanFilter = appliedFilters.find(
        (f) => f.operator === lessThanKey,
      );
      if (lessThanFilter) {
        setFilterFieldValue1(
          getDaysSinceIntTimestamp(parseInt(lessThanFilter?.value)),
        );
      }

      const greaterThanFilter = appliedFilters.find(
        (f) => f.operator === greaterThanKey,
      );
      if (greaterThanFilter) {
        setFilterFieldValue2(
          getDaysSinceIntTimestamp(parseInt(greaterThanFilter?.value)),
        );
      }
    } else if (key === campaignsKey) {
      const appliedFilters = filters?.[key] || [];

      if (appliedFilters.length) {
        // Set applied filter
        const appliedFilter = appliedFilters[0].value;
        const formattedFilter = appliedFilter.split(', ');
        setFilterFieldValue1(formattedFilter);
      } else {
        // Set default (all options)
        const labelMap = clientFilterFieldLabelMap[key]['labelMap'];
        const optionKeys = Object.keys(labelMap).sort();
        const campaignIds = Object.keys(campaignNameMap);
        const campaignOptionKeys = [emptyArrayValue];
        campaignIds.map((id) => {
          optionKeys.map((k) => {
            const str = `${id}${intraStringSeparatorChar}${k}`;
            campaignOptionKeys.push(str);
          });
        });
        setFilterFieldValue1(campaignOptionKeys);
      }
    } else if (key === tagsKey) {
      const appliedFilters = filters?.[key] || [];

      if (appliedFilters.length) {
        // Set applied filter
        const appliedFilter = appliedFilters[0].value;
        const formattedFilter = appliedFilter.split(', ');
        setFilterFieldValue1(formattedFilter);
      } else {
        // Set default (all tags)
        const tagOptions = [emptyArrayValue, ...tags];
        setFilterFieldValue1(tagOptions);
      }
    } else if (
      key === membershipStatusKey ||
      key === serviceCategoriesUsedKey ||
      key === dndKey ||
      key === genderKey ||
      key === contactTypeKey ||
      key === canBeTextedKey
    ) {
      const appliedFilters = filters?.[key] || [];

      if (appliedFilters.length) {
        // Set applied filter
        const appliedFilter = appliedFilters[0].value;
        const formattedFilter = appliedFilter.split(', ');
        setFilterFieldValue1(formattedFilter);
      } else {
        // Set default (all options)
        const labelMap =
          key === serviceCategoriesUsedKey
            ? categoryLabelMap
            : clientFilterFieldLabelMap[key]['labelMap'];
        let optionKeys =
          key === serviceCategoriesUsedKey ? [emptyArrayValue] : [];
        optionKeys = [...optionKeys, ...Object.keys(labelMap).sort()];
        setFilterFieldValue1(optionKeys);
      }
    }
  };

  const clearFilterValues = () => {
    setFilterFieldOperator1();
    setFilterFieldValue1('');
    setFilterFieldOperator2();
    setFilterFieldValue2('');
    setFilterFieldValue3('');
    setFilterFieldValue4('');
    setFieldToFilterOnIsNull();
  };

  const onClickApplyFilter = (appliedFilters) => {
    onApply(appliedFilters);
    const typesenseFilterString = getTypesenseFilterString(
      [locationId],
      appliedFilters,
    );
    setUpdatedFilterString(typesenseFilterString);
    setValuesChangedFlag(true);

    onClickBackFromField();
  };

  const onClickApplyTemplate = (template) => {
    const filters = template.filter;
    const filterToApply = getClientFilterMapFromString(filters);
    onClickApplyFilter(filterToApply);
    onClose();
  };

  const onCreateClientFilterTemplate = () => {
    const filterString = getTypesenseFilterString([locationId], filters);

    createClientFilterTemplateMutation({
      variables: {
        name: 'Untitled',
        filter: filterString,
      },
      onCompleted: async (data) => {
        const createdTemplate = data.createClientFilterTemplate;
        if (createdTemplate) {
          await refetchClientFilterTemplates();
          setTemplateToEditId(createdTemplate.id);
          setTab(templatesTab);
          setSnackbarMessage('Template created');
        }
      },
    });
  };

  const onEditClientFilterTemplate = () => {
    editClientFilterTemplateMutation({
      variables: {
        id: templateToEditId,
        name: templateToEditName,
      },
      onCompleted: async (data) => {
        const success = data.editClientFilterTemplate.success;

        if (success) {
          await refetchClientFilterTemplates();
          setTemplateToEditId();
          setTemplateToEditName();
          setSnackbarMessage('Template updated');
        }
      },
    });
  };

  const onDeleteClientFilterTemplate = (idToDelete) => {
    deleteClientFilterTemplateMutation({
      variables: {
        id: idToDelete,
      },
      onCompleted: async (data) => {
        const success = data.deleteClientFilterTemplate.success;

        if (success) {
          await refetchClientFilterTemplates();
          setSnackbarMessage('Template deleted');
        }
      },
    });
  };

  const onClickBackFromField = () => {
    setFieldToFilterOn();
    clearFilterValues();
  };

  const onResetFilterForField = (field) => {
    const appliedFilters = { ...filters };
    delete appliedFilters[field];
    onClickApplyFilter(appliedFilters);
    clearFilterValues();
    setFieldToFilterOnIsNull();
  };

  const filtersApplied = Object.keys(filters)?.length > 0;

  const campaignStatusOptions = [
    {
      campaignString: emptyArrayValue,
    },
  ];
  Object.keys(campaignNameMap).map((id) =>
    Object.keys(campaignMemberStatusLabelMap).map((statusKey) => {
      const campaignString = `${id}${intraStringSeparatorChar}${statusKey}`;
      campaignStatusOptions.push({ campaignId: id, campaignString, statusKey });
    }),
  );
  // Available options = all campaign permutations
  const numCampaignStatusOptions = campaignStatusOptions.length;

  const applyFilterText = isEditing ? `Update filter` : `Apply filter`;
  const campaignName = campaignToEdit?.name || '';
  const formattedCampaignName =
    campaignName.length > 30 ? `${campaignName.slice(0, 30)}...` : campaignName;

  const excludedFilterFields = getExcludedFilterFields(context);

  const lowercaseSearchFilter = searchFilter?.toLowerCase();
  let hasMatchingServices = false;

  const allAvailableServiceIds = [];
  const allDisplayedServices = [];
  const categorySelectedMap = {};
  const categoryServicesMap = {};
  const formattedCategories = sortedAvailableSessionTypes?.map(
    (sessionTypeCategory) => {
      const categoryId = sessionTypeCategory.categoryId;
      let wholeCategorySelected = true;
      const categoryServiceIds = [];
      const services = sessionTypeCategory.services.filter((s) => {
        const id = s.id;
        const filtered =
          !searchFilter ||
          s.displayName?.toLowerCase().includes(lowercaseSearchFilter);

        if (filtered) {
          categoryServiceIds.push(id);
          if (wholeCategorySelected && !selectedServices[id]) {
            wholeCategorySelected = false;
          }
        }

        allAvailableServiceIds.push(id);

        return filtered;
      });
      categorySelectedMap[categoryId] =
        wholeCategorySelected && !!services.length;
      categoryServicesMap[categoryId] = categoryServiceIds;

      allDisplayedServices.push(...services);
      return { ...sessionTypeCategory, services };
    },
  );
  const numDisplayed = allDisplayedServices.length;
  const numSelected = Object.keys(selectedServices).length;
  const allServicesSelected = numSelected === numDisplayed + 1;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        maxWidth={830}
      >
        <ModalContainer>
          {isEditing ? (
            <ColumnCenteredDiv>
              <MediumText>
                Edit Smart List for Campaign:
                <br></br>'{formattedCampaignName}'
              </MediumText>
            </ColumnCenteredDiv>
          ) : (
            <>
              <MLText>Filter Contacts</MLText>
              <SMText>{formattedCampaignName}</SMText>
            </>
          )}
          <ColumnCenteredDiv topMargin={-15}>
            {!isEditing && (
              <Tabs
                value={tab}
                onChange={(_, key) => setTab(key)}
                addBottomMargin
              >
                <Tab
                  value={fieldsTab}
                  label='By fields'
                />
                <Tab
                  value={templatesTab}
                  label='By template'
                />
              </Tabs>
            )}
            {tab === fieldsTab ? (
              <>
                {!fieldToFilterOn ? (
                  <>
                    <FilterFieldsTitleContainer>
                      <MediumDarkSmallText>
                        {isEditing
                          ? `Select fields to include in your smart list`
                          : `Select fields to filter on`}
                      </MediumDarkSmallText>
                      <SaveFilterIconContainer>
                        {!isEditing && (
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {filtersApplied
                                  ? 'Save filter as template'
                                  : 'Filter fields to save template'}
                              </TooltipTitleText>
                            }
                            placement='bottom'
                          >
                            <BookmarkIcon
                              disabled={!filtersApplied}
                              onClick={() =>
                                filtersApplied && onCreateClientFilterTemplate()
                              }
                            />
                          </Tooltip>
                        )}
                      </SaveFilterIconContainer>
                    </FilterFieldsTitleContainer>
                    <FieldsContainer>
                      {clientFilterFieldKeys.map(
                        (key, idx) =>
                          !excludedFilterFields.includes(key) && (
                            <FieldContainer
                              end={idx === clientFilterFieldKeys.length - 1}
                            >
                              <ClickableMediumDarkEssText
                                onClick={() => {
                                  setFieldToFilterOn(key);
                                  setDefaultFilterValues(
                                    clientFilterFieldLabelMap[key]['type'],
                                    key,
                                  );
                                }}
                              >
                                {clientFilterFieldLabelMap[key]['label']}
                                <BrightGreenText>
                                  {key === nameKey
                                    ? filters[firstNameKey] ||
                                      filters[lastNameKey]
                                      ? ' (Filtered)'
                                      : ''
                                    : filters[key]
                                    ? ' (Filtered)'
                                    : ''}
                                </BrightGreenText>
                              </ClickableMediumDarkEssText>
                              <StyledNextIcon
                                onClick={() => {
                                  setFieldToFilterOn(key);
                                  setDefaultFilterValues(
                                    clientFilterFieldLabelMap[key]['type'],
                                    key,
                                  );
                                }}
                              />
                            </FieldContainer>
                          ),
                      )}
                    </FieldsContainer>
                    <ModalActions>
                      {isEditing && (
                        <MediumPrimaryButton
                          disabled={!valuesChangedFlag}
                          onClick={() => {
                            handleUpdate(
                              campaignToEdit.id,
                              updatedFilterString,
                            );
                          }}
                        >
                          {updateSmartListMutationLoading
                            ? 'Updating...'
                            : 'Save changes'}
                        </MediumPrimaryButton>
                      )}
                      <MediumSecondaryButton onClick={handleClose}>
                        Close
                      </MediumSecondaryButton>
                    </ModalActions>
                  </>
                ) : (
                  <>
                    <MediumDarkSmallText>
                      Edit '
                      {clientFilterFieldLabelMap[fieldToFilterOn]['label']}'
                      filter
                    </MediumDarkSmallText>
                    {fieldToFilterOn === contactTypeKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <MediumDarkEssText>
                                {
                                  clientFilterFieldLabelMap[fieldToFilterOn][
                                    'extendedLabel'
                                  ]
                                }
                              </MediumDarkEssText>
                              {Object.keys(contactTypeLabelMap).map(
                                (typeKey) => (
                                  <CenteredCheckboxFieldContainer>
                                    <SmallCheckbox
                                      checked={filterFieldValue1.includes(
                                        typeKey,
                                      )}
                                      onClick={() => {
                                        if (
                                          filterFieldValue1.includes(typeKey)
                                        ) {
                                          const updatedFilter = [
                                            ...filterFieldValue1,
                                          ].filter((item) => item !== typeKey);
                                          setFilterFieldValue1(updatedFilter);
                                        } else {
                                          const updatedFilter = [
                                            ...filterFieldValue1,
                                            typeKey,
                                          ];
                                          setFilterFieldValue1(updatedFilter);
                                        }
                                      }}
                                    />
                                    <MediumDarkLargeTinyText>
                                      {contactTypeLabelMap[typeKey]}
                                    </MediumDarkLargeTinyText>
                                  </CenteredCheckboxFieldContainer>
                                ),
                              )}
                            </FilterFieldContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const appliedFilters = { ...filters };

                              if (filterFieldValue1) {
                                const allOptions = Object.keys(
                                  clientFilterFieldLabelMap[fieldToFilterOn][
                                    'labelMap'
                                  ],
                                );

                                if (
                                  filterFieldValue1.length ===
                                  allOptions.length *
                                    Object.keys(contactTypeLabelMap).length
                                ) {
                                  delete appliedFilters[fieldToFilterOn];
                                } else {
                                  const formattedArrayValues =
                                    filterFieldValue1.join(', ');
                                  const filterObject1 = {
                                    key: fieldToFilterOn,
                                    value: formattedArrayValues,
                                    operator: containsArrayItems,
                                  };
                                  appliedFilters[fieldToFilterOn] = [
                                    filterObject1,
                                  ];
                                }

                                onClickApplyFilter(appliedFilters);
                              }
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === campaignsKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <EmptyGapColumnCenteredDiv>
                                <MediumDarkEssText>
                                  Campaign Enrollment
                                </MediumDarkEssText>
                              </EmptyGapColumnCenteredDiv>
                              <CheckboxFieldContainer>
                                <SmallCheckbox
                                  checked={
                                    filterFieldValue1.length ===
                                    numCampaignStatusOptions
                                  }
                                  onClick={() => {
                                    if (
                                      filterFieldValue1?.length ===
                                      numCampaignStatusOptions
                                    ) {
                                      setFilterFieldValue1([]);
                                    } else {
                                      setFilterFieldValue1(
                                        campaignStatusOptions.map(
                                          (o) => o['campaignString'],
                                        ),
                                      );
                                    }
                                  }}
                                />
                                <MediumDarkLargeTinyText>
                                  Select all
                                </MediumDarkLargeTinyText>
                              </CheckboxFieldContainer>
                              {campaignStatusOptions.map((option) => {
                                const {
                                  campaignId,
                                  campaignString,
                                  statusKey,
                                } = option;

                                const selected =
                                  filterFieldValue1.includes(campaignString);
                                const statusLabel =
                                  campaignMemberStatusLabelMap[statusKey];

                                return (
                                  <CheckboxFieldContainer>
                                    {campaignString === emptyArrayValue ? (
                                      <>
                                        <SmallCheckbox
                                          checked={filterFieldValue1.includes(
                                            emptyArrayValue,
                                          )}
                                          onClick={() => {
                                            const selected =
                                              filterFieldValue1.includes(
                                                emptyArrayValue,
                                              );

                                            if (selected) {
                                              const updatedFilter = [
                                                ...filterFieldValue1,
                                              ].filter(
                                                (item) =>
                                                  item !== emptyArrayValue,
                                              );
                                              setFilterFieldValue1(
                                                updatedFilter,
                                              );
                                            } else {
                                              const updatedFilter = [
                                                ...filterFieldValue1,
                                                emptyArrayValue,
                                              ];
                                              setFilterFieldValue1(
                                                updatedFilter,
                                              );
                                            }
                                          }}
                                        />
                                        <StartAlignedMediumDarkLargeTinyText>
                                          Not in any campaigns
                                        </StartAlignedMediumDarkLargeTinyText>
                                      </>
                                    ) : (
                                      <>
                                        <SmallCheckbox
                                          checked={selected}
                                          onClick={() => {
                                            if (selected) {
                                              const updatedFilter = [
                                                ...filterFieldValue1,
                                              ].filter(
                                                (item) =>
                                                  item !== campaignString,
                                              );
                                              setFilterFieldValue1(
                                                updatedFilter,
                                              );
                                            } else {
                                              const updatedFilter = [
                                                ...filterFieldValue1,
                                                campaignString,
                                              ];
                                              setFilterFieldValue1(
                                                updatedFilter,
                                              );
                                            }
                                          }}
                                        />
                                        <StartAlignedMediumDarkLargeTinyText>
                                          {statusLabel} -- '
                                          {campaignNameMap[campaignId]}'
                                        </StartAlignedMediumDarkLargeTinyText>
                                      </>
                                    )}
                                  </CheckboxFieldContainer>
                                );
                              })}
                            </FilterFieldContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const appliedFilters = { ...filters };

                              if (filterFieldValue1) {
                                const allOptions = Object.keys(
                                  clientFilterFieldLabelMap[fieldToFilterOn][
                                    'labelMap'
                                  ],
                                );
                                if (
                                  // Possible statuses * num campaigns + 1 for none key
                                  filterFieldValue1.length ===
                                  allOptions.length *
                                    Object.keys(campaignNameMap).length +
                                    1
                                ) {
                                  delete appliedFilters[fieldToFilterOn];
                                } else {
                                  const formattedArrayValues =
                                    filterFieldValue1.join(', ');
                                  const filterObject1 = {
                                    key: fieldToFilterOn,
                                    value: formattedArrayValues,
                                    operator: containsArrayItems,
                                  };
                                  appliedFilters[fieldToFilterOn] = [
                                    filterObject1,
                                  ];
                                }
                                onClickApplyFilter(appliedFilters);
                              }
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === lastMessagedAtKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsSection>
                            <FilterFieldsContainer>
                              <FilterFieldContainer>
                                <MediumDarkEssText>
                                  {
                                    clientFilterFieldLabelMap[fieldToFilterOn][
                                      'extendedLabel'
                                    ]
                                  }
                                  {clientFilterFieldLabelMap[fieldToFilterOn][
                                    'units'
                                  ]
                                    ? ` (${clientFilterFieldLabelMap[fieldToFilterOn]['units']})`
                                    : ''}
                                </MediumDarkEssText>
                                <FilterOperatorsContainer>
                                  <FilterOperatorContainer>
                                    <FilterOperatorText>
                                      Greater than or equal to
                                    </FilterOperatorText>
                                  </FilterOperatorContainer>
                                </FilterOperatorsContainer>
                                <ModularWidthInput
                                  value={filterFieldValue1}
                                  onChange={(e) =>
                                    setFilterFieldValue1(e.target.value)
                                  }
                                />
                              </FilterFieldContainer>
                              <FilterFieldContainer>
                                <FilterOperatorsContainer>
                                  <FilterOperatorContainer>
                                    <FilterOperatorText>
                                      Lesser than or equal to
                                    </FilterOperatorText>
                                  </FilterOperatorContainer>
                                </FilterOperatorsContainer>
                                <ModularWidthInput
                                  value={filterFieldValue2}
                                  onChange={(e) => {
                                    setFilterFieldValue2(e.target.value);
                                  }}
                                />
                              </FilterFieldContainer>
                            </FilterFieldsContainer>
                          </FilterFieldsSection>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const filterFieldToModify = fieldToFilterOn;

                              const appliedFilters = { ...filters };

                              delete appliedFilters[filterFieldToModify];

                              let currentFilter =
                                appliedFilters[filterFieldToModify] || [];

                              const currentDate = new Date();

                              // Greater than and last than keys are swapped here because of how lastMessagedAt is stored in Typesense

                              if (filterFieldValue1) {
                                const startDateFilter = new Date(currentDate);
                                startDateFilter.setDate(
                                  startDateFilter.getDate() - filterFieldValue1,
                                );

                                const filterObject1 = {
                                  key: filterFieldToModify,
                                  value: startDateFilter.getTime(),
                                  operator: lessThanKey,
                                };

                                currentFilter = [
                                  ...currentFilter,
                                  filterObject1,
                                ];
                                appliedFilters[filterFieldToModify] =
                                  currentFilter;
                              }

                              if (filterFieldValue2) {
                                const endDateFilter = new Date(currentDate);
                                endDateFilter.setDate(
                                  endDateFilter.getDate() - filterFieldValue2,
                                );

                                const filterObject2 = {
                                  key: filterFieldToModify,
                                  value: endDateFilter.getTime(),
                                  operator: greaterThanKey,
                                };

                                currentFilter = [
                                  ...currentFilter,
                                  filterObject2,
                                ];
                                appliedFilters[filterFieldToModify] =
                                  currentFilter;
                              }

                              appliedFilters[filterFieldToModify] = [
                                ...currentFilter,
                                {
                                  key: filterFieldToModify,
                                  value: emptyIntValue,
                                  operator: doesNotEqualKey,
                                },
                              ];

                              onClickApplyFilter(appliedFilters);
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === dndKey ||
                      fieldToFilterOn === canBeTextedKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <MediumDarkEssText>
                                {
                                  clientFilterFieldLabelMap[fieldToFilterOn][
                                    'extendedLabel'
                                  ]
                                }
                              </MediumDarkEssText>
                              <CenteredCheckboxFieldContainer>
                                <SmallCheckbox
                                  checked={filterFieldValue1.includes('true')}
                                  onClick={() => {
                                    if (filterFieldValue1.includes('true')) {
                                      const updatedFilter = [
                                        ...filterFieldValue1,
                                      ].filter((item) => item !== 'true');
                                      setFilterFieldValue1(updatedFilter);
                                    } else {
                                      const updatedFilter = [
                                        ...filterFieldValue1,
                                        'true',
                                      ];
                                      setFilterFieldValue1(updatedFilter);
                                    }
                                  }}
                                />
                                <MediumDarkLargeTinyText>
                                  Yes
                                </MediumDarkLargeTinyText>
                              </CenteredCheckboxFieldContainer>
                              <CenteredCheckboxFieldContainer>
                                <SmallCheckbox
                                  checked={filterFieldValue1.includes('false')}
                                  onClick={() => {
                                    if (filterFieldValue1.includes('false')) {
                                      const updatedFilter = [
                                        ...filterFieldValue1,
                                      ].filter((item) => item !== 'false');
                                      setFilterFieldValue1(updatedFilter);
                                    } else {
                                      const updatedFilter = [
                                        ...filterFieldValue1,
                                        'false',
                                      ];
                                      setFilterFieldValue1(updatedFilter);
                                    }
                                  }}
                                />
                                <MediumDarkLargeTinyText>
                                  No
                                </MediumDarkLargeTinyText>
                              </CenteredCheckboxFieldContainer>
                            </FilterFieldContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const appliedFilters = { ...filters };

                              if (filterFieldValue1) {
                                const allOptions = Object.keys(
                                  clientFilterFieldLabelMap[fieldToFilterOn][
                                    'labelMap'
                                  ],
                                );
                                if (
                                  // Possible statuses * num campaigns + 1 for none key
                                  filterFieldValue1.length === allOptions.length
                                ) {
                                  delete appliedFilters[fieldToFilterOn];
                                } else {
                                  const formattedArrayValues =
                                    filterFieldValue1.join(', ');
                                  const filterObject1 = {
                                    key: fieldToFilterOn,
                                    value: formattedArrayValues,
                                    operator: containsArrayItems,
                                  };
                                  appliedFilters[fieldToFilterOn] = [
                                    filterObject1,
                                  ];
                                }
                                onClickApplyFilter(appliedFilters);
                              }
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === nameKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <MediumDarkEssText>First Name</MediumDarkEssText>
                              <FilterOperatorsContainer>
                                <FilterOperatorContainer>
                                  {filterFieldOperator1 === equalsKey ? (
                                    <CheckedRadioButton />
                                  ) : (
                                    <UncheckedRadioButton
                                      onClick={() => {
                                        if (
                                          filterFieldOperator1 !== equalsKey
                                        ) {
                                          setFilterFieldOperator1(equalsKey);
                                        }
                                      }}
                                    />
                                  )}
                                  <FilterOperatorText>
                                    Equals
                                  </FilterOperatorText>
                                </FilterOperatorContainer>
                                <FilterOperatorContainer>
                                  {filterFieldOperator1 === doesNotEqualKey ? (
                                    <CheckedRadioButton />
                                  ) : (
                                    <UncheckedRadioButton
                                      onClick={() => {
                                        if (
                                          filterFieldOperator1 !==
                                          doesNotEqualKey
                                        ) {
                                          setFilterFieldOperator1(
                                            doesNotEqualKey,
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  <FilterOperatorText>
                                    Does not equal
                                  </FilterOperatorText>
                                </FilterOperatorContainer>
                              </FilterOperatorsContainer>
                              <ModularWidthInput
                                value={filterFieldValue1}
                                onChange={(e) =>
                                  setFilterFieldValue1(e.target.value)
                                }
                              />
                            </FilterFieldContainer>
                            <FilterFieldContainer>
                              <MediumDarkEssText>Last Name</MediumDarkEssText>
                              <FilterOperatorsContainer>
                                <FilterOperatorContainer>
                                  {filterFieldOperator2 === equalsKey ? (
                                    <CheckedRadioButton />
                                  ) : (
                                    <UncheckedRadioButton
                                      onClick={() => {
                                        if (
                                          filterFieldOperator2 !== equalsKey
                                        ) {
                                          setFilterFieldOperator2(equalsKey);
                                        }
                                      }}
                                    />
                                  )}
                                  <FilterOperatorText>
                                    Equals
                                  </FilterOperatorText>
                                </FilterOperatorContainer>
                                <FilterOperatorContainer>
                                  {filterFieldOperator2 === doesNotEqualKey ? (
                                    <CheckedRadioButton />
                                  ) : (
                                    <UncheckedRadioButton
                                      onClick={() => {
                                        if (
                                          filterFieldOperator2 !==
                                          doesNotEqualKey
                                        ) {
                                          setFilterFieldOperator2(
                                            doesNotEqualKey,
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  <FilterOperatorText>
                                    Does not equal
                                  </FilterOperatorText>
                                </FilterOperatorContainer>
                              </FilterOperatorsContainer>
                              <ModularWidthInput
                                value={filterFieldValue2}
                                onChange={(e) =>
                                  setFilterFieldValue2(e.target.value)
                                }
                              />
                            </FilterFieldContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const appliedFilters = { ...filters };

                              if (filterFieldValue1) {
                                const firstNameFilter = {
                                  key: firstNameKey,
                                  value: filterFieldValue1,
                                  operator: filterFieldOperator1,
                                };

                                appliedFilters[firstNameKey] = [
                                  firstNameFilter,
                                ];
                              } else {
                                delete appliedFilters[firstNameKey];
                              }

                              if (filterFieldValue2) {
                                const lastNameFilter = {
                                  key: lastNameKey,
                                  value: filterFieldValue2,
                                  operator: filterFieldOperator2,
                                };
                                appliedFilters[lastNameKey] = [lastNameFilter];
                              } else {
                                delete appliedFilters[lastNameKey];
                              }

                              onClickApplyFilter(appliedFilters);
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === emailKey ||
                      fieldToFilterOn === phoneKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <MediumDarkEssText>
                                {
                                  clientFilterFieldLabelMap[fieldToFilterOn][
                                    'extendedLabel'
                                  ]
                                }
                              </MediumDarkEssText>
                              <FilterOperatorsContainer>
                                <FilterOperatorContainer>
                                  {filterFieldOperator1 === equalsKey ? (
                                    <CheckedRadioButton />
                                  ) : (
                                    <UncheckedRadioButton
                                      onClick={() => {
                                        if (
                                          filterFieldOperator1 !== equalsKey
                                        ) {
                                          setFilterFieldOperator1(equalsKey);
                                        }
                                      }}
                                    />
                                  )}
                                  <FilterOperatorText>
                                    Equals
                                  </FilterOperatorText>
                                </FilterOperatorContainer>
                                <FilterOperatorContainer>
                                  {filterFieldOperator1 === doesNotEqualKey ? (
                                    <CheckedRadioButton />
                                  ) : (
                                    <UncheckedRadioButton
                                      onClick={() => {
                                        if (
                                          filterFieldOperator1 !==
                                          doesNotEqualKey
                                        ) {
                                          setFilterFieldOperator1(
                                            doesNotEqualKey,
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  <FilterOperatorText>
                                    Does not equal
                                  </FilterOperatorText>
                                </FilterOperatorContainer>
                              </FilterOperatorsContainer>
                              <ModularWidthInput
                                value={filterFieldValue1}
                                onChange={(e) =>
                                  setFilterFieldValue1(e.target.value)
                                }
                              />
                            </FilterFieldContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const appliedFilters = { ...filters };
                              if (filterFieldValue1) {
                                const filterObject1 = {
                                  key: fieldToFilterOn,
                                  value: filterFieldValue1,
                                  operator: filterFieldOperator1,
                                };

                                appliedFilters[fieldToFilterOn] = [
                                  filterObject1,
                                ];
                              } else {
                                delete appliedFilters[fieldToFilterOn];
                              }

                              onClickApplyFilter(appliedFilters);
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === upcomingVisitsKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsSection>
                            <FilterFieldsContainer>
                              <FilterFieldContainer>
                                <MediumDarkEssText>
                                  Has Upcoming Visits
                                </MediumDarkEssText>
                                <FilterOperatorsContainer>
                                  <FilterOperatorContainer>
                                    {fieldToFilterOnIsNull === false ? (
                                      <CheckedRadioButton />
                                    ) : (
                                      <UncheckedRadioButton
                                        onClick={() => {
                                          if (fieldToFilterOnIsNull !== false) {
                                            setFieldToFilterOnIsNull(false);
                                          }
                                        }}
                                      />
                                    )}
                                    <FilterOperatorText>Yes</FilterOperatorText>
                                  </FilterOperatorContainer>
                                  <FilterOperatorContainer>
                                    {fieldToFilterOnIsNull === true ? (
                                      <CheckedRadioButton />
                                    ) : (
                                      <UncheckedRadioButton
                                        onClick={() => {
                                          if (fieldToFilterOnIsNull !== true) {
                                            setFieldToFilterOnIsNull(true);
                                          }
                                        }}
                                      />
                                    )}
                                    <FilterOperatorText>No</FilterOperatorText>
                                  </FilterOperatorContainer>
                                  <FilterOperatorContainer>
                                    {fieldToFilterOnIsNull == null ? (
                                      <CheckedRadioButton />
                                    ) : (
                                      <UncheckedRadioButton
                                        onClick={() => {
                                          if (fieldToFilterOnIsNull != null) {
                                            setFieldToFilterOnIsNull();
                                            setFilterFieldValue1('');
                                            setFilterFieldValue2('');
                                          }
                                        }}
                                      />
                                    )}
                                    <FilterOperatorText>
                                      Either
                                    </FilterOperatorText>
                                  </FilterOperatorContainer>
                                </FilterOperatorsContainer>
                              </FilterFieldContainer>
                            </FilterFieldsContainer>
                            {fieldToFilterOnIsNull !== true && (
                              <>
                                <FilterFieldsContainer>
                                  <FilterFieldContainer>
                                    <MediumDarkEssText>
                                      Number of Upcoming Visits
                                    </MediumDarkEssText>
                                    <FilterOperatorsContainer>
                                      <FilterOperatorContainer>
                                        <FilterOperatorText>
                                          Greater than or equal to
                                        </FilterOperatorText>
                                      </FilterOperatorContainer>
                                    </FilterOperatorsContainer>
                                    <ModularWidthInput
                                      value={filterFieldValue3}
                                      onChange={(e) => {
                                        setFilterFieldValue3(e.target.value);
                                        setFieldToFilterOnIsNull(false);
                                      }}
                                    />
                                  </FilterFieldContainer>
                                  <FilterFieldContainer>
                                    <FilterOperatorsContainer>
                                      <FilterOperatorContainer>
                                        <FilterOperatorText>
                                          Lesser than or equal to
                                        </FilterOperatorText>
                                      </FilterOperatorContainer>
                                    </FilterOperatorsContainer>
                                    <ModularWidthInput
                                      value={filterFieldValue4}
                                      onChange={(e) => {
                                        setFilterFieldValue4(e.target.value);
                                        setFieldToFilterOnIsNull(false);
                                      }}
                                    />
                                  </FilterFieldContainer>
                                </FilterFieldsContainer>
                                <FilterFieldsContainer>
                                  <FilterFieldContainer>
                                    <MediumDarkEssText>
                                      {
                                        clientFilterFieldLabelMap[
                                          fieldToFilterOn
                                        ]['extendedLabel']
                                      }
                                      {clientFilterFieldLabelMap[
                                        fieldToFilterOn
                                      ]['units']
                                        ? ` (${clientFilterFieldLabelMap[fieldToFilterOn]['units']})`
                                        : ''}
                                    </MediumDarkEssText>
                                    <FilterOperatorsContainer>
                                      <FilterOperatorContainer>
                                        <FilterOperatorText>
                                          Greater than or equal to
                                        </FilterOperatorText>
                                      </FilterOperatorContainer>
                                    </FilterOperatorsContainer>
                                    <ModularWidthInput
                                      value={filterFieldValue1}
                                      onChange={(e) => {
                                        setFilterFieldValue1(e.target.value);
                                        setFieldToFilterOnIsNull(false);
                                      }}
                                    />
                                  </FilterFieldContainer>
                                  <FilterFieldContainer>
                                    <FilterOperatorsContainer>
                                      <FilterOperatorContainer>
                                        <FilterOperatorText>
                                          Lesser than or equal to
                                        </FilterOperatorText>
                                      </FilterOperatorContainer>
                                    </FilterOperatorsContainer>
                                    <ModularWidthInput
                                      value={filterFieldValue2}
                                      onChange={(e) => {
                                        setFilterFieldValue2(e.target.value);
                                        setFieldToFilterOnIsNull(false);
                                      }}
                                    />
                                  </FilterFieldContainer>
                                </FilterFieldsContainer>
                              </>
                            )}
                          </FilterFieldsSection>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const filterFieldsToModify = [
                                fieldToFilterOn,
                                numUpcomingVisitsKey,
                              ];

                              const appliedFilters = { ...filters };

                              if (fieldToFilterOnIsNull === true) {
                                delete appliedFilters[fieldToFilterOn];
                                delete appliedFilters[numUpcomingVisitsKey];

                                appliedFilters[fieldToFilterOn] = [
                                  {
                                    key: fieldToFilterOn,
                                    value: -1,
                                    operator: equalsKey,
                                  },
                                ];
                              } else if (fieldToFilterOnIsNull === false) {
                                for (
                                  let i = 0;
                                  i < filterFieldsToModify.length;
                                  i++
                                ) {
                                  const filterFieldToModify =
                                    filterFieldsToModify[i];

                                  delete appliedFilters[filterFieldToModify];

                                  let currentFilter =
                                    appliedFilters[filterFieldToModify] || [];

                                  if (i === 0) {
                                    if (filterFieldValue1) {
                                      const filterObject1 = {
                                        key: filterFieldToModify,
                                        value:
                                          filterFieldToModify === distanceKey
                                            ? filterFieldValue1 * 1.6 * 1000
                                            : filterFieldValue1,
                                        operator: greaterThanOrEqualToKey,
                                      };

                                      currentFilter = [
                                        ...currentFilter,
                                        filterObject1,
                                      ];
                                      appliedFilters[filterFieldToModify] =
                                        currentFilter;
                                    }
                                    if (filterFieldValue2) {
                                      const filterObject2 = {
                                        key: filterFieldToModify,
                                        value:
                                          filterFieldToModify === distanceKey
                                            ? filterFieldValue2 * 1.6 * 1000
                                            : filterFieldValue2,
                                        operator: lessThanOrEqualToKey,
                                      };

                                      currentFilter = [
                                        ...currentFilter,
                                        filterObject2,
                                      ];
                                      appliedFilters[filterFieldToModify] =
                                        currentFilter;
                                    }
                                  } else if (i === 1) {
                                    if (filterFieldValue3) {
                                      const filterObject3 = {
                                        key: filterFieldToModify,
                                        value:
                                          filterFieldToModify === distanceKey
                                            ? filterFieldValue3 * 1.6 * 1000
                                            : filterFieldValue3,
                                        operator: greaterThanOrEqualToKey,
                                      };

                                      currentFilter = [
                                        ...currentFilter,
                                        filterObject3,
                                      ];
                                      appliedFilters[filterFieldToModify] =
                                        currentFilter;
                                    }
                                    if (filterFieldValue4) {
                                      const filterObject4 = {
                                        key: filterFieldToModify,
                                        value:
                                          filterFieldToModify === distanceKey
                                            ? filterFieldValue4 * 1.6 * 1000
                                            : filterFieldValue4,
                                        operator: lessThanOrEqualToKey,
                                      };

                                      currentFilter = [
                                        ...currentFilter,
                                        filterObject4,
                                      ];
                                      appliedFilters[filterFieldToModify] =
                                        currentFilter;
                                    }
                                  }

                                  appliedFilters[filterFieldToModify] = [
                                    ...currentFilter,
                                    {
                                      key: filterFieldToModify,
                                      value: -1,
                                      operator: doesNotEqualKey,
                                    },
                                  ];
                                }
                              } else {
                                delete appliedFilters[fieldToFilterOn];
                              }

                              onClickApplyFilter(appliedFilters);
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === lastVisitKey ||
                      fieldToFilterOn === firstVisitKey ||
                      fieldToFilterOn === numVisitsKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsSection>
                            {fieldToFilterOn !== numVisitsKey && (
                              <FilterFieldsContainer>
                                <FilterFieldContainer>
                                  <MediumDarkEssText>
                                    Has Visited Before
                                  </MediumDarkEssText>
                                  <FilterOperatorsContainer>
                                    <FilterOperatorContainer>
                                      {fieldToFilterOnIsNull === false ? (
                                        <CheckedRadioButton />
                                      ) : (
                                        <UncheckedRadioButton
                                          onClick={() => {
                                            if (
                                              fieldToFilterOnIsNull !== false
                                            ) {
                                              setFieldToFilterOnIsNull(false);
                                            }
                                          }}
                                        />
                                      )}
                                      <FilterOperatorText>
                                        Yes
                                      </FilterOperatorText>
                                    </FilterOperatorContainer>
                                    <FilterOperatorContainer>
                                      {fieldToFilterOnIsNull === true ? (
                                        <CheckedRadioButton />
                                      ) : (
                                        <UncheckedRadioButton
                                          onClick={() => {
                                            if (
                                              fieldToFilterOnIsNull !== true
                                            ) {
                                              setFieldToFilterOnIsNull(true);
                                            }
                                          }}
                                        />
                                      )}
                                      <FilterOperatorText>
                                        No
                                      </FilterOperatorText>
                                    </FilterOperatorContainer>
                                    <FilterOperatorContainer>
                                      {fieldToFilterOnIsNull == null ? (
                                        <CheckedRadioButton />
                                      ) : (
                                        <UncheckedRadioButton
                                          onClick={() => {
                                            if (fieldToFilterOnIsNull != null) {
                                              setFieldToFilterOnIsNull();
                                              setFilterFieldValue1('');
                                              setFilterFieldValue2('');
                                            }
                                          }}
                                        />
                                      )}
                                      <FilterOperatorText>
                                        Either
                                      </FilterOperatorText>
                                    </FilterOperatorContainer>
                                  </FilterOperatorsContainer>
                                </FilterFieldContainer>
                              </FilterFieldsContainer>
                            )}
                            {(fieldToFilterOnIsNull !== true ||
                              fieldToFilterOn === numVisitsKey) && (
                              <>
                                <FilterFieldsContainer>
                                  <FilterFieldContainer>
                                    <MediumDarkEssText>
                                      {
                                        clientFilterFieldLabelMap[
                                          fieldToFilterOn
                                        ]['extendedLabel']
                                      }
                                      {clientFilterFieldLabelMap[
                                        fieldToFilterOn
                                      ]['units']
                                        ? ` (${clientFilterFieldLabelMap[fieldToFilterOn]['units']})`
                                        : ''}
                                    </MediumDarkEssText>
                                    <FilterOperatorsContainer>
                                      <FilterOperatorContainer>
                                        <FilterOperatorText>
                                          Greater than or equal to
                                        </FilterOperatorText>
                                      </FilterOperatorContainer>
                                    </FilterOperatorsContainer>
                                    <ModularWidthInput
                                      value={filterFieldValue1}
                                      onChange={(e) => {
                                        setFilterFieldValue1(e.target.value);
                                        if (fieldToFilterOnIsNull !== false) {
                                          setFieldToFilterOnIsNull(false);
                                        }
                                      }}
                                    />
                                  </FilterFieldContainer>
                                  <FilterFieldContainer>
                                    <FilterOperatorsContainer>
                                      <FilterOperatorContainer>
                                        <FilterOperatorText>
                                          Lesser than or equal to
                                        </FilterOperatorText>
                                      </FilterOperatorContainer>
                                    </FilterOperatorsContainer>
                                    <ModularWidthInput
                                      value={filterFieldValue2}
                                      onChange={(e) => {
                                        setFilterFieldValue2(e.target.value);
                                        if (fieldToFilterOnIsNull !== false) {
                                          setFieldToFilterOnIsNull(false);
                                        }
                                      }}
                                    />
                                  </FilterFieldContainer>
                                </FilterFieldsContainer>
                              </>
                            )}
                          </FilterFieldsSection>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            onClick={() => {
                              const filterFieldToModify = fieldToFilterOn;

                              const appliedFilters = { ...filters };

                              delete appliedFilters[filterFieldToModify];
                              let currentFilter =
                                appliedFilters[filterFieldToModify] || [];

                              if (fieldToFilterOnIsNull === true) {
                                delete appliedFilters[fieldToFilterOn];

                                appliedFilters[fieldToFilterOn] = [
                                  {
                                    key: fieldToFilterOn,
                                    value: -1,
                                    operator: equalsKey,
                                  },
                                ];
                              } else if (fieldToFilterOnIsNull === false) {
                                if (filterFieldValue1) {
                                  const filterObject1 = {
                                    key: filterFieldToModify,
                                    value:
                                      filterFieldToModify === distanceKey
                                        ? filterFieldValue1 * 1.6 * 1000
                                        : filterFieldValue1,
                                    operator: greaterThanOrEqualToKey,
                                  };

                                  currentFilter = [
                                    ...currentFilter,
                                    filterObject1,
                                  ];
                                  appliedFilters[filterFieldToModify] =
                                    currentFilter;
                                }

                                if (filterFieldValue2) {
                                  const filterObject2 = {
                                    key: filterFieldToModify,
                                    value:
                                      filterFieldToModify === distanceKey
                                        ? filterFieldValue2 * 1.6 * 1000
                                        : filterFieldValue2,
                                    operator: lessThanOrEqualToKey,
                                  };

                                  currentFilter = [
                                    ...currentFilter,
                                    filterObject2,
                                  ];
                                  appliedFilters[filterFieldToModify] =
                                    currentFilter;
                                }

                                appliedFilters[filterFieldToModify] = [
                                  ...currentFilter,
                                  {
                                    key: filterFieldToModify,
                                    value: -1,
                                    operator: doesNotEqualKey,
                                  },
                                ];
                              } else {
                                delete appliedFilters[fieldToFilterOn];
                              }

                              onClickApplyFilter(appliedFilters);
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                          {!isEditing && (
                            <MediumSecondaryButton
                              onClick={() => {
                                onResetFilterForField(fieldToFilterOn);
                              }}
                            >
                              Reset
                            </MediumSecondaryButton>
                          )}
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === genderKey ||
                      fieldToFilterOn === serviceCategoriesUsedKey ||
                      fieldToFilterOn === membershipStatusKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <EmptyGapColumnCenteredDiv>
                                <MediumDarkEssText>
                                  {
                                    clientFilterFieldLabelMap[fieldToFilterOn][
                                      'extendedLabel'
                                    ]
                                  }
                                  {clientFilterFieldLabelMap[fieldToFilterOn][
                                    'units'
                                  ]
                                    ? ` (${clientFilterFieldLabelMap[fieldToFilterOn]['units']})`
                                    : ''}
                                </MediumDarkEssText>
                                <ToggleAllOptionsText
                                  onClick={() => {
                                    let allOptions =
                                      fieldToFilterOn ===
                                      serviceCategoriesUsedKey
                                        ? [emptyArrayValue]
                                        : [];
                                    allOptions = [
                                      ...allOptions,
                                      ...Object.keys(
                                        fieldToFilterOn ===
                                          serviceCategoriesUsedKey
                                          ? categoryLabelMap
                                          : clientFilterFieldLabelMap[
                                              fieldToFilterOn
                                            ]['labelMap'],
                                      ),
                                    ];
                                    if (
                                      filterFieldValue1.length ===
                                      allOptions.length
                                    ) {
                                      setFilterFieldValue1([]);
                                    } else {
                                      setFilterFieldValue1(allOptions);
                                    }
                                  }}
                                >
                                  Toggle all
                                </ToggleAllOptionsText>
                              </EmptyGapColumnCenteredDiv>
                            </FilterFieldContainer>
                            <SelectOptionContainer>
                              {fieldToFilterOn === serviceCategoriesUsedKey && (
                                <SelectOptionChip
                                  selected={filterFieldValue1.includes(
                                    emptyArrayValue,
                                  )}
                                  onClick={() => {
                                    if (
                                      filterFieldValue1.includes(
                                        emptyArrayValue,
                                      )
                                    ) {
                                      const updatedFilter = [
                                        ...filterFieldValue1,
                                      ].filter(
                                        (item) => item !== emptyArrayValue,
                                      );
                                      setFilterFieldValue1(updatedFilter);
                                    } else {
                                      const updatedFilter = [
                                        ...filterFieldValue1,
                                        emptyArrayValue,
                                      ];
                                      setFilterFieldValue1(updatedFilter);
                                    }
                                  }}
                                >
                                  <SelectOptionText>None</SelectOptionText>
                                </SelectOptionChip>
                              )}
                              {Object.keys(
                                fieldToFilterOn === serviceCategoriesUsedKey
                                  ? categoryLabelMap
                                  : clientFilterFieldLabelMap[fieldToFilterOn][
                                      'labelMap'
                                    ],
                              ).map((k) => {
                                const selected = filterFieldValue1.includes(k);
                                const labelMap =
                                  fieldToFilterOn === serviceCategoriesUsedKey
                                    ? categoryLabelMap
                                    : clientFilterFieldLabelMap[
                                        fieldToFilterOn
                                      ]['labelMap'];
                                return (
                                  <SelectOptionChip
                                    selected={selected}
                                    onClick={() => {
                                      if (selected) {
                                        const updatedFilter = [
                                          ...filterFieldValue1,
                                        ].filter((item) => item !== k);
                                        setFilterFieldValue1(updatedFilter);
                                      } else {
                                        const updatedFilter = [
                                          ...filterFieldValue1,
                                          k,
                                        ];
                                        setFilterFieldValue1(updatedFilter);
                                      }
                                    }}
                                  >
                                    <SelectOptionText>
                                      {labelMap[k]}
                                    </SelectOptionText>
                                  </SelectOptionChip>
                                );
                              })}
                            </SelectOptionContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            disabled={filterFieldValue1?.length === 0}
                            onClick={() => {
                              const appliedFilters = { ...filters };

                              if (filterFieldValue1) {
                                const allOptions = Object.keys(
                                  fieldToFilterOn === serviceCategoriesUsedKey
                                    ? categoryLabelMap
                                    : clientFilterFieldLabelMap[
                                        fieldToFilterOn
                                      ]['labelMap'],
                                );
                                if (
                                  fieldToFilterOn === serviceCategoriesUsedKey
                                ) {
                                  allOptions.push('None');
                                }
                                if (
                                  filterFieldValue1.length === allOptions.length
                                ) {
                                  delete appliedFilters[fieldToFilterOn];
                                } else {
                                  const formattedArrayValues =
                                    filterFieldValue1.join(', ');
                                  const filterObject1 = {
                                    key: fieldToFilterOn,
                                    value: formattedArrayValues,
                                    operator: containsArrayItems,
                                  };
                                  appliedFilters[fieldToFilterOn] = [
                                    filterObject1,
                                  ];
                                }
                                onClickApplyFilter(appliedFilters);
                              }
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === lastVisitServicesKey ? (
                      <>
                        <FieldsContainer
                          disableScroll
                          largeHeight
                        >
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => {
                                onClickBackFromField();
                                setStagingSearchFilter();
                                setSearchFilter();
                              }}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <MediumDarkEssText smallLineHeight>
                              Services received in last visit
                              <LightDarkLargeTinyText smallLineHeight>
                                {numSelected} selected {textSeparatorChar}{' '}
                                <ToggleAllOptionsTextSpan
                                  onClick={() => {
                                    if (numSelected) {
                                      setSelectedServices({});
                                    } else {
                                      const updatedSelectedServices = {
                                        [emptyArrayValue]: 'None',
                                      };
                                      allDisplayedServices.map((s) => {
                                        const id = s.id;
                                        updatedSelectedServices[id] =
                                          s.displayName;
                                      });
                                      setSelectedServices(
                                        updatedSelectedServices,
                                      );
                                    }
                                  }}
                                >
                                  Toggle
                                </ToggleAllOptionsTextSpan>
                              </LightDarkLargeTinyText>
                            </MediumDarkEssText>
                            <OptionsSearchBoxContainer topMargin={10}>
                              <ModularWidthInput
                                value={stagingSearchFilter}
                                onChange={(e) =>
                                  setStagingSearchFilter(e.target.value)
                                }
                                placeholder='Search'
                                InputProps={{
                                  endAdornment: (
                                    <ClearInputIcon
                                      onClick={() => setStagingSearchFilter('')}
                                    />
                                  ),
                                }}
                                extraSmallHeight
                                widthPercentage={75}
                              />
                            </OptionsSearchBoxContainer>
                            <ListInputSection widthPercentage={80}>
                              <AutoScrollWrapper fixedMaxHeight={300}>
                                {!searchFilter && (
                                  <OptionItemContainer
                                    widthPercentage={95}
                                    removeBottomMargin
                                  >
                                    <OptionSubitemTextContainer>
                                      <StartAlignedErrorLargeTinyText start>
                                        No services received
                                      </StartAlignedErrorLargeTinyText>
                                    </OptionSubitemTextContainer>
                                    <OptionActionsContainer>
                                      <Tooltip
                                        title={
                                          <TooltipTitleText>
                                            {!!selectedServices[emptyArrayValue]
                                              ? `Uns`
                                              : 'S'}
                                            elect
                                          </TooltipTitleText>
                                        }
                                      >
                                        <SmallCheckbox
                                          removePadding
                                          checked={
                                            !!selectedServices[emptyArrayValue]
                                          }
                                          onClick={() => {
                                            if (
                                              !!selectedServices[
                                                emptyArrayValue
                                              ]
                                            ) {
                                              const updatedSelectedServices = {
                                                ...selectedServices,
                                              };
                                              delete updatedSelectedServices[
                                                emptyArrayValue
                                              ];
                                              setSelectedServices(
                                                updatedSelectedServices,
                                              );
                                            } else {
                                              setSelectedServices({
                                                ...selectedServices,
                                                [emptyArrayValue]: 'None',
                                              });
                                            }
                                          }}
                                        />
                                      </Tooltip>
                                    </OptionActionsContainer>
                                  </OptionItemContainer>
                                )}
                                {formattedCategories.map(
                                  (sessionTypeCategory, idx) => {
                                    const {
                                      categoryId,
                                      categoryName,
                                      services,
                                    } = sessionTypeCategory;
                                    const categoryLabel = `${
                                      categoryName || 'Uncategorized'
                                    }`;

                                    const allCategoryServicesSelected =
                                      categorySelectedMap[categoryId];

                                    const numServices = services.length;
                                    if (!hasMatchingServices && numServices) {
                                      hasMatchingServices = true;
                                    }
                                    const collapsed =
                                      collapsedCategoryIds[categoryId];

                                    return numServices ? (
                                      <>
                                        <OptionItemContainer
                                          top
                                          widthPercentage={95}
                                          bottomMargin={-10}
                                        >
                                          <StartAlignedFlexDiv>
                                            <StartAlignedMediumDarkExtraSmallText
                                              smallLineHeight
                                            >
                                              {categoryLabel} ({numServices})
                                            </StartAlignedMediumDarkExtraSmallText>
                                            <CenteredDiv>
                                              {collapsed ? (
                                                <DownArrow
                                                  color='darkGray'
                                                  pointer
                                                  onClick={() => {
                                                    const updated = {
                                                      ...collapsedCategoryIds,
                                                    };
                                                    delete updated[categoryId];
                                                    setCollapsedCategoryIds(
                                                      updated,
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <UpArrow
                                                  color='darkGray'
                                                  pointer
                                                  onClick={() => {
                                                    const updated = {
                                                      ...collapsedCategoryIds,
                                                    };
                                                    updated[categoryId] = true;
                                                    setCollapsedCategoryIds(
                                                      updated,
                                                    );
                                                  }}
                                                />
                                              )}
                                              <Tooltip
                                                title={
                                                  <TooltipTitleText>
                                                    {allCategoryServicesSelected
                                                      ? `Unselect all`
                                                      : 'Select all'}{' '}
                                                    services for category
                                                  </TooltipTitleText>
                                                }
                                                placement='bottom'
                                              >
                                                <SmallCheckbox
                                                  darkGray
                                                  removePadding
                                                  checked={
                                                    allCategoryServicesSelected
                                                  }
                                                  onClick={() => {
                                                    const serviceIdsForCategory =
                                                      categoryServicesMap[
                                                        categoryId
                                                      ];
                                                    const updatedSelectedServices =
                                                      {
                                                        ...selectedServices,
                                                      };
                                                    serviceIdsForCategory.map(
                                                      (id) => {
                                                        if (
                                                          allCategoryServicesSelected
                                                        ) {
                                                          delete updatedSelectedServices[
                                                            id
                                                          ];
                                                        } else {
                                                          updatedSelectedServices[
                                                            id
                                                          ] = true;
                                                        }
                                                      },
                                                    );
                                                    setSelectedServices(
                                                      updatedSelectedServices,
                                                    );
                                                  }}
                                                />
                                              </Tooltip>
                                            </CenteredDiv>
                                          </StartAlignedFlexDiv>
                                        </OptionItemContainer>
                                        {!collapsed && (
                                          <>
                                            {sortBy(
                                              services,
                                              (s) => s.displayName,
                                            ).map((s, idx) => {
                                              const { id, displayName, type } =
                                                s;

                                              const isPackage =
                                                categoryName === packagesLabel;
                                              const formattedType = isPackage
                                                ? packageKey
                                                : type;

                                              const selected =
                                                !!selectedServices[id];

                                              return (
                                                <OptionItemContainer
                                                  bottom={
                                                    idx === numServices - 1
                                                  }
                                                  widthPercentage={95}
                                                >
                                                  <OptionSubitemTextContainer>
                                                    <OptionSubitemText start>
                                                      {displayName}
                                                      {formattedType !==
                                                        dropInKey &&
                                                        ` (${sessionTypeTypesMap[formattedType]})`}
                                                    </OptionSubitemText>
                                                  </OptionSubitemTextContainer>
                                                  <OptionActionsContainer>
                                                    <Tooltip
                                                      title={
                                                        <TooltipTitleText>
                                                          {selected
                                                            ? `Uns`
                                                            : 'S'}
                                                          elect
                                                        </TooltipTitleText>
                                                      }
                                                    >
                                                      <SmallCheckbox
                                                        removePadding
                                                        checked={selected}
                                                        onClick={() => {
                                                          if (selected) {
                                                            const updatedSelectedServices =
                                                              {
                                                                ...selectedServices,
                                                              };
                                                            delete updatedSelectedServices[
                                                              id
                                                            ];
                                                            setSelectedServices(
                                                              updatedSelectedServices,
                                                            );
                                                          } else {
                                                            setSelectedServices(
                                                              {
                                                                ...selectedServices,
                                                                [id]: displayName,
                                                              },
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </OptionActionsContainer>
                                                </OptionItemContainer>
                                              );
                                            })}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {!hasMatchingServices &&
                                          idx ===
                                            sortedAvailableSessionTypes?.length -
                                              1 && (
                                            <ColumnCenteredDiv topMargin={50}>
                                              <LargeNoDataIcon />
                                              <LightPrimaryEssText>
                                                No matching results
                                              </LightPrimaryEssText>
                                            </ColumnCenteredDiv>
                                          )}
                                      </>
                                    );
                                  },
                                )}
                              </AutoScrollWrapper>
                            </ListInputSection>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            disabled={numSelected === 0}
                            onClick={() => {
                              const appliedFilters = { ...filters };
                              if (numSelected) {
                                if (allServicesSelected) {
                                  delete appliedFilters[lastVisitServicesKey];
                                } else {
                                  const operator =
                                    numSelected < maxAllowedForServicesQuery
                                      ? containsArrayItems
                                      : doesntContainArrayItems;

                                  let value = '';
                                  if (operator === containsArrayItems) {
                                    value =
                                      Object.keys(selectedServices).join(', ');
                                  } else {
                                    allAvailableServiceIds.map((id, idx) => {
                                      if (!selectedServices[id]) {
                                        value +=
                                          idx ===
                                          allAvailableServiceIds.length - 1
                                            ? id
                                            : `${id},`;
                                      }
                                    });
                                    if (!selectedServices[emptyArrayValue]) {
                                      value += value
                                        ? `, ${emptyArrayValue}`
                                        : `${emptyArrayValue}`;
                                    }
                                  }
                                  if (value?.charAt(value.length - 1) === ',') {
                                    value = value.slice(0, -1);
                                  }

                                  const filterObject1 = {
                                    key: lastVisitServicesKey,
                                    value,
                                    operator,
                                  };
                                  appliedFilters[lastVisitServicesKey] = [
                                    filterObject1,
                                  ];
                                }
                                onClickApplyFilter(appliedFilters);
                              }
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : fieldToFilterOn === tagsKey ? (
                      <>
                        <FieldsContainer>
                          <BackToFieldsContainer>
                            <StyledBackIcon
                              onClick={() => onClickBackFromField()}
                            />
                            <MediumDarkEssText>Back</MediumDarkEssText>
                          </BackToFieldsContainer>
                          <FilterFieldsContainer>
                            <FilterFieldContainer>
                              <EmptyGapColumnCenteredDiv>
                                <MediumDarkEssText>
                                  {
                                    clientFilterFieldLabelMap[fieldToFilterOn][
                                      'extendedLabel'
                                    ]
                                  }
                                  {clientFilterFieldLabelMap[fieldToFilterOn][
                                    'units'
                                  ]
                                    ? ` (${clientFilterFieldLabelMap[fieldToFilterOn]['units']})`
                                    : ''}
                                </MediumDarkEssText>
                                <ToggleAllOptionsText
                                  onClick={() => {
                                    const allOptions = [
                                      emptyArrayValue,
                                      ...tags,
                                    ];
                                    if (
                                      filterFieldValue1.length ===
                                      allOptions.length
                                    ) {
                                      setFilterFieldValue1([]);
                                    } else {
                                      setFilterFieldValue1(allOptions);
                                    }
                                  }}
                                >
                                  Toggle all
                                </ToggleAllOptionsText>
                              </EmptyGapColumnCenteredDiv>
                            </FilterFieldContainer>
                            <SelectOptionContainer>
                              <SelectOptionChip
                                selected={filterFieldValue1.includes(
                                  emptyArrayValue,
                                )}
                                onClick={() => {
                                  if (
                                    filterFieldValue1.includes(emptyArrayValue)
                                  ) {
                                    const updatedFilter = [
                                      ...filterFieldValue1,
                                    ].filter(
                                      (item) => item !== emptyArrayValue,
                                    );
                                    setFilterFieldValue1(updatedFilter);
                                  } else {
                                    const updatedFilter = [
                                      ...filterFieldValue1,
                                      emptyArrayValue,
                                    ];
                                    setFilterFieldValue1(updatedFilter);
                                  }
                                }}
                              >
                                <SelectOptionText>None</SelectOptionText>
                              </SelectOptionChip>
                              {[...tags]?.sort()?.map((tag) => {
                                const selected =
                                  filterFieldValue1.includes(tag);
                                const tagLabel =
                                  tag.length > 20
                                    ? `${tag.slice(0, 17)}...`
                                    : tag;
                                return (
                                  <SelectOptionChip
                                    selected={selected}
                                    onClick={() => {
                                      if (selected) {
                                        const updatedFilter = [
                                          ...filterFieldValue1,
                                        ].filter((item) => item !== tag);
                                        setFilterFieldValue1(updatedFilter);
                                      } else {
                                        const updatedFilter = [
                                          ...filterFieldValue1,
                                          tag,
                                        ];
                                        setFilterFieldValue1(updatedFilter);
                                      }
                                    }}
                                  >
                                    <SelectOptionText>
                                      {tagLabel}
                                    </SelectOptionText>
                                  </SelectOptionChip>
                                );
                              })}
                            </SelectOptionContainer>
                          </FilterFieldsContainer>
                        </FieldsContainer>
                        <ModalActions>
                          <MediumPrimaryButton
                            disabled={filterFieldValue1?.length === 0}
                            onClick={() => {
                              const appliedFilters = { ...filters };

                              if (filterFieldValue1) {
                                const allOptions = [...tags, emptyArrayValue];
                                if (
                                  filterFieldValue1.length === allOptions.length
                                ) {
                                  delete appliedFilters[fieldToFilterOn];
                                } else {
                                  const formattedArrayValues =
                                    filterFieldValue1.join(', ');
                                  const filterObject1 = {
                                    key: fieldToFilterOn,
                                    value: formattedArrayValues,
                                    operator: containsArrayItems,
                                  };
                                  appliedFilters[fieldToFilterOn] = [
                                    filterObject1,
                                  ];
                                }
                                onClickApplyFilter(appliedFilters);
                              }
                            }}
                          >
                            {applyFilterText}
                          </MediumPrimaryButton>
                        </ModalActions>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : tab === templatesTab ? (
              <>
                {!templateToEditId ? (
                  <TemplatesContainer>
                    {clientFilterTemplatesLoading ||
                    deleteClientFilterTemplateLoading ? (
                      <LoadingIndicator fullScreen />
                    ) : !clientFilterTemplates?.length ? (
                      <FullSizeCenteredDiv>
                        <LightDarkSmallText>
                          No templates created
                        </LightDarkSmallText>
                      </FullSizeCenteredDiv>
                    ) : (
                      <>
                        {clientFilterTemplates.map((template, idx) => {
                          const id = template.id;
                          const name = template.name;

                          return (
                            <TemplateContainer
                              end={idx === clientFilterTemplates.length - 1}
                            >
                              <ClickableMediumDarkEssText
                                onClick={() => {
                                  setTemplateToEditId(id);
                                }}
                                textAlign={'start'}
                              >
                                {name}
                              </ClickableMediumDarkEssText>
                              <TemplateActionsContainer>
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Apply filter
                                    </TooltipTitleText>
                                  }
                                  placement='bottom'
                                >
                                  <ListCheckIcon
                                    onClick={() =>
                                      onClickApplyTemplate(template)
                                    }
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Edit name
                                    </TooltipTitleText>
                                  }
                                  placement='bottom'
                                >
                                  <ListEditIcon
                                    onClick={() => {
                                      setTemplateToEditId(id);
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Delete template
                                    </TooltipTitleText>
                                  }
                                  placement='bottom'
                                >
                                  <ListRemoveIcon
                                    onClick={() =>
                                      onDeleteClientFilterTemplate(id)
                                    }
                                  />
                                </Tooltip>
                              </TemplateActionsContainer>
                            </TemplateContainer>
                          );
                        })}
                      </>
                    )}
                  </TemplatesContainer>
                ) : (
                  <>
                    <EditTemplateContainer>
                      {templateLoading ? (
                        <LoadingIndicator
                          fullScreen
                          size={45}
                        />
                      ) : (
                        <>
                          <EditTemplateTitleContainer>
                            <BackToTemplatesContainer>
                              <StyledBackIcon
                                onClick={() => {
                                  setTemplateToEditId();
                                  setTemplateToEditName();
                                }}
                              />
                            </BackToTemplatesContainer>
                            <MediumDarkEssText>
                              Edit Filter Template Name
                            </MediumDarkEssText>
                          </EditTemplateTitleContainer>
                          <ModularWidthInput
                            value={templateToEditName}
                            onChange={(e) =>
                              setTemplateToEditName(e.target.value)
                            }
                          />
                        </>
                      )}
                    </EditTemplateContainer>
                    <ModalActions>
                      <MediumSecondaryButton
                        onClick={onEditClientFilterTemplate}
                        disabled={!templateToEditName}
                      >
                        Save
                      </MediumSecondaryButton>
                    </ModalActions>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </ColumnCenteredDiv>
        </ModalContainer>
      </Modal>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default FilterClientsModal;

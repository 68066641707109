import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { STORE_TWILIO_ACCESS_TOKEN } from '../../../api/mutations/app-connections';
import {
  ColumnCenteredDiv,
  Modal,
  ModalActions,
  ModalContainer,
  ModalTitleText,
  PrimaryButton,
} from '../../../styles/shared-styled-components';
import Input from '../../Form/Input/Input';

const TwilioAccessTokenModal = ({ isOpen, onClose, onSuccess }) => {
  const [storeTwilioAccessTokenMutation] = useMutation(
    STORE_TWILIO_ACCESS_TOKEN,
  );

  const [accessToken, setAccessToken] = useState('');

  const onSave = () => {
    storeTwilioAccessTokenMutation({
      variables: {
        accessToken,
      },
      onCompleted: async () => {
        await onSuccess();
        setAccessToken();
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <ModalTitleText>Update Your Twilio Access Token</ModalTitleText>
        <Input
          id='accesstoken'
          value={accessToken}
          onChange={(e) => {
            setAccessToken(e.target.value);
          }}
          label='Access Token'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
        />
        <ModalActions>
          <ColumnCenteredDiv>
            <PrimaryButton
              disabled={!accessToken}
              onClick={onSave}
            >
              Save
            </PrimaryButton>
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default TwilioAccessTokenModal;
